<template>
  <validation-observer
    ref="refFormObserver"
    #default="{ pristine }"
  >
    <b-modal
      id="modal-reservation-modify-add-other-special-services"
      :title="'Thêm dịch vụ phòng chờ thương gia'"
      title-class="text-airline font-medium-4 font-weight-bolder mb-0"
      body-class="px-75"
      footer-class="px-50"
      centered
      no-close-on-backdrop
      size="xl"
      @show="handleShowModal"
      @hide="handleHideModal"
    >
      <template #modal-footer="{ close }">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-secondary px-1 px-sm-2"
          class="center rounded-pill"
          @click="close()"
        >
          {{ $t('reservation.back') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="success"
          class="px-1 px-sm-2"
          :disabled="!isConfirm || pristine"
          pill
          @click="handleAddOtherSpecialServices"
        >
          <span class="align-middle"> Thanh toán ngay </span>
        </b-button>
      </template>

      <IAmOverlay
        :loading="isLoading"
        :spinner-variant="'info'"
      >

        <b-card
          class="border"
          body-class="p-75"
        >
          <b-row>
            <!-- ANCHOR Phone -->
            <b-col
              cols="12"
              lg="6"
            >
              <validation-provider
                #default="validationContext"
                name="Phone"
                rules="required"
              >
                <b-form-group label-for="phone">
                  <template #label>
                    Số điện thoại liên hệ <span class="text-danger">(*)</span>
                  </template>
                  <b-form-input
                    id="phone"
                    v-model="phoneNumber"
                    v-remove-non-numeric-chars.allNumber
                    placeholder="Vui lòng nhập"
                    :state="getValidationState(validationContext) === false ? false : null"
                    lazy-formatter
                    :formatter="trimInput"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- ANCHOR Email -->
            <b-col
              cols="12"
              lg="6"
            >
              <validation-provider
                #default="validationContext"
                name="Email"
                rules="required|email"
              >
                <b-form-group label-for="email">
                  <template #label>
                    Email liên hệ <span class="text-danger">(*)</span>
                  </template>
                  <b-form-input
                    id="email"
                    v-model="email"
                    placeholder="Vui lòng nhập"
                    :state="getValidationState(validationContext) === false ? false : null"
                    lazy-formatter
                    :formatter="trimInput"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          v-for="(paxItem, indexPaxItem) in dataForm"
          :key="indexPaxItem"
          header-bg-variant="light-warning"
          header-class="py-25 px-25 px-md-1"
          class="border-warning"
          body-class="p-75"
        >
          <template #header>
            <div class="text-body fw-700">
              Hành khách #{{ indexPaxItem + 1 }}
            </div>

            <div>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-info rounded-lg"
                class="fw-700 mr-1 px-75 py-50 py-md-75 px-md-1"
                :disabled="hasAllData(paxItem)"
                @click="handleOneForAll(paxItem)"
              >
                Áp dụng tất cả
              </b-button>

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger rounded-lg px-1 py-50 py-md-75 px-md-2"
                class="fw-700"
                @click="handleRemovePaxItem(indexPaxItem)"
              >
                Xoá
              </b-button>
            </div>
          </template>

          <!-- ANCHOR Flight -->
          <b-row>
            <!-- ANCHOR  Tên khách hàng -->
            <b-col
              cols="12"
              lg="6"
              xl="4"
            >
              <validation-provider
                #default="validationContext"
                name="Tên khách hàng sử dụng vé"
                rules="required"
                :vid="`passenger-name-${indexPaxItem}`"
              >
                <b-form-group :state="getValidationState(validationContext) === false ? false : null">
                  <template #label>
                    Tên khách hàng sử dụng <span class="text-danger">(*)</span>
                  </template>
                  <v-select
                    :id="`passenger-name-${indexPaxItem}`"
                    v-model="paxItem.pax"
                    class="select_custom"
                    :clearable="false"
                    :searchable="true"
                    label="firstName"
                    placeholder=" Vui lòng chọn hành khách"
                    :options="bookingData.paxLists"
                  >
                    <template #selected-option="data">
                      <div class="fw-700">
                        <small class="font-weight-bold text-body font-medium-1  mr-1">({{ $t(`reservation.${data.paxType}`) }})</small>
                        <span class="font-weight-bolder text-info">{{ data.lastName }} {{ data.firstName }}</span>
                      </div>
                    </template>
                    <template #option="data">
                      <div class="fw-700">
                        <span class="font-weight-bold text-body font-medium-1 mr-1">({{ $t(`reservation.${data.paxType}`) }})</span>
                        <span class="font-weight-bolder">{{ data.lastName }} {{ data.firstName }}</span>
                      </div>
                    </template>
                    <template #no-options>
                      {{ $t('flight.noOptions') }}
                    </template>
                  </v-select>
                  <span
                    v-if="validationContext.errors.length > 0"
                    class="text-danger"
                    style="font-size:12px"
                  >
                    {{ validationContext.errors[0] }}
                  </span>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- ANCHOR - Hành trình -->
            <b-col
              cols="12"
              lg="6"
              xl="4"
              class="px-xl-0"
            >
              <validation-provider
                #default="validationContext"
                name="Hành trình"
                rules="required"
                :vid="`flight-select-${indexPaxItem}`"
              >
                <b-form-group :state="getValidationState(validationContext) === false ? false : null">
                  <template #label>
                    Chọn hành trình <span class="text-danger">(*)</span>
                  </template>

                  <v-select
                    :id="`flight-select-${indexPaxItem}`"
                    v-model="paxItem.tripSelected"
                    class="select_custom"
                    :clearable="false"
                    :searchable="false"
                    label="devAddonsSegmentIndex"
                    placeholder=" Chọn hành trình"
                    :options="getSegmentOptions(bookingData)"
                  >
                    <template #selected-option="data">
                      <div class="d-flex flex-wrap text-info font-weight-bolder">
                        {{ convertShortTrip(data, false) }}
                      </div>
                    </template>
                    <template #option="data">
                      <div class="d-flex flex-wrap fw-700">
                        {{ convertShortTrip(data, false) }}
                      </div>
                    </template>
                    <template #no-options>
                      {{ $t('flight.noOptions') }}
                    </template>
                  </v-select>

                  <span
                    v-if="validationContext.errors.length > 0"
                    class="text-danger"
                    style="font-size:12px"
                  >
                    {{ validationContext.errors[0] }}
                  </span>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- ANCHOR Chọn sân bay -->
            <b-col
              cols="12"
              lg="6"
              xl="4"
            >
              <validation-provider
                #default="validationContext"
                name="Sân bay"
                rules="required"
                :vid="`airport-select-${indexPaxItem}`"
              >
                <b-form-group :state="getValidationState(validationContext) === false ? false : null">
                  <template #label>
                    Chọn sân bay <span class="text-danger">(*)</span>
                  </template>

                  <v-select
                    :id="`airport-select-${indexPaxItem}`"
                    v-model="paxItem.airportSelected"
                    class="select_custom"
                    :clearable="false"
                    :searchable="true"
                    label="name"
                    placeholder=" Chọn sân bay"
                    :options="getSpecialServicesList(specialServicesList)"
                    @option:selected="() => {
                      paxItem.serviceSelected = null;
                      paxItem.loungeFare = null;
                    }"
                  >
                    <template #selected-option="data">
                      <div class="d-flex flex-wrap text-info font-weight-bolder">
                        {{ data.name }}
                      </div>
                    </template>
                    <template #option="data">
                      <div class="d-flex flex-wrap fw-700">
                        {{ data.name }}
                      </div>
                    </template>
                    <template #no-options>
                      {{ $t('flight.noOptions') }}
                    </template>
                  </v-select>
                  <span
                    v-if="validationContext.errors.length > 0"
                    class="text-danger"
                    style="font-size:12px"
                  >
                    {{ validationContext.errors[0] }}
                  </span>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- ANCHOR Chọn phòng chờ -->
            <b-col
              cols="7"
              lg="6"
            >
              <!-- class="pr-0 pr-md-1" -->
              <validation-provider
                #default="validationContext"
                name="Phòng chờ"
                rules="required"
                :vid="`lounge-select-${indexPaxItem}`"
              >
                <b-form-group :state="getValidationState(validationContext) === false ? false : null">
                  <template #label>
                    Chọn phòng chờ <span class="text-danger">(*)</span>
                  </template>
                  <v-select
                    :id="`lounge-select-${indexPaxItem}`"
                    v-model="paxItem.serviceSelected"
                    class="select_custom"
                    :clearable="false"
                    :searchable="true"
                    label="name"
                    placeholder=" Chọn phòng chờ"
                    :options="getLoungeServicesList(paxItem.airportSelected)"
                    @option:selected="() => {paxItem.loungeFare = null}"
                  >
                    <template #selected-option="data">
                      <div class="d-flex flex-wrap text-info font-weight-bolder">
                        {{ data.name }} ({{
                          data.isDomestic ? 'Nội địa' : 'Quốc tế'
                        }})
                      </div>
                    </template>
                    <template #option="data">
                      <div class="d-flex flex-wrap fw-700">
                        {{ data.name }} ({{
                          data.isDomestic ? 'Nội địa' : 'Quốc tế'
                        }})
                      </div>
                    </template>
                    <template #no-options>
                      {{
                        paxItem.airportSelected
                          ? $t('flight.noOptions')
                          : 'Vui lòng chọn sân bay trước.'
                      }}
                    </template>
                  </v-select>
                  <span
                    v-if="validationContext.errors.length > 0"
                    class="text-danger"
                    style="font-size:12px"
                  >
                    {{ validationContext.errors[0] }}
                  </span>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="5"
              lg="6"
              class="pl-0 pl-md-1"
            >
              <validation-provider
                #default="validationContext"
                name="Loại vé"
                rules="required"
                :vid="`fare-select-${indexPaxItem}`"
              >
                <b-form-group :state="getValidationState(validationContext) === false ? false : null">
                  <template #label>
                    Chọn loại vé <span class="text-danger">(*)</span>
                  </template>
                  <v-select
                    :id="`fare-select-${indexPaxItem}`"
                    v-model="paxItem.loungeFare"
                    class="select_custom"
                    :clearable="false"
                    :searchable="true"
                    label="name"
                    placeholder=" Chọn loại vé"
                    :options="getLoungeServiceFareList(paxItem.serviceSelected)"
                  >
                    <template #selected-option="data">
                      <div class="d-flex flex-nowrap text-info font-weight-bolder">
                        {{ data.name }} <span class="text-warning ml-50">({{ formatCurrency(data.price) }})</span>
                      </div>
                    </template>
                    <template #option="data">
                      <div class="d-flex flex-nowrap fw-700">
                        {{ data.name }} <span class="text-warning ml-50">({{ formatCurrency(data.price) }})</span>
                      </div>
                    </template>
                    <template #no-options>
                      {{
                        paxItem.serviceSelected
                          ? $t('flight.noOptions')
                          : 'Vui lòng chọn phòng chờ trước!'
                      }}
                    </template>
                  </v-select>
                  <span
                    v-if="validationContext.errors.length > 0"
                    class="text-danger"
                    style="font-size:12px"
                  >
                    {{ validationContext.errors[0] }}
                  </span>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card>

        <div>
          <b-button
            variant="outline-info"
            class="rounded-lg"
            @click="addPassengerHandle()"
          >
            Thêm hành khách
          </b-button>
        </div>

        <b-row class="justify-content-center align-items-center mt-2 fw-700">
          Tổng tiền cần thanh toán: <span class="text-airline fw-800 ml-50 font-medium-2"> {{ formatCurrency(totalPriceServices) }}</span>
        </b-row>

        <IAmConfirm :is-confirm.sync="isConfirm" />

      </IAmOverlay>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BModal,
  BButton,
  BFormGroup,
  BCard,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormInput,
  BRow,
  BCol,
  BFormInvalidFeedback,
  BPopover,
  BTooltip,
} from 'bootstrap-vue'
import {
  ref, toRefs, watch, computed,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { cloneDeep, isEmpty } from 'lodash'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'

import {
  convertShortTrip,
  convertISODateTime,
  formatCurrency,
} from '@/@core/utils/filter'

import {
  removeAccentsUpperCaseFormatter,
  upperCaseFormatter,
  trimInput,
} from '@core/comp-functions/forms/formatter-input'
import formValidation from '@core/comp-functions/forms/form-validation'
import BCardCode from '@core/components/b-card-code'

import useReservationHandle from '@reservation/useReservationHandle'

import useToast from '@useToast'

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BFormInput,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BPopover,
    BTooltip,
    flatPickr,
    BCardCode,
    vSelect,

    ValidationObserver,
    ValidationProvider,

    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    IAmConfirm: () => import('@/components/IAmConfirm.vue'),
  },
  directives: {
    Ripple,
  },
  props: {
    bookingData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { getListSpecialServices, createSpecialServices } = useReservationHandle()
    const { bookingData } = toRefs(props)
    const { toastError } = useToast()

    const loungeDefaultReq = {
      type: 'LOUNGE',
      pax: null,
      bookingCode: null,
      source: null,
      flightNumber: null,
      flightTrip: null,
      servingFrom: null,
      airportCode: null,
      airportLoungeId: null,

      tripSelected: null,
      airportSelected: null,
      serviceSelected: null,
      loungeFare: null,
    }

    const email = ref(null)
    const phoneNumber = ref(null)
    const dataForm = ref([])

    function getSegmentOptions(bookingData) {
      if (isEmpty(bookingData.itineraries)) return []
      return bookingData.itineraries.flatMap(segment => segment).filter(seg => ['SGN'].includes(seg.departure.iataCode))
    }

    // FIXME: Hiện tại chọn hành trình SGN trên vé nếu chỉ có 1 segment thoả mãn
    // RESET
    const resetLoungeReqData = () => {
      dataForm.value = [{
        ...cloneDeep(loungeDefaultReq),
        pax: bookingData.value.paxLists[0],
        tripSelected: getSegmentOptions(bookingData.value).length === 1 ? getSegmentOptions(bookingData.value)[0] : null,
      }]
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetLoungeReqData)

    const defaultValueAirportSelected = ref(null)
    // FIXME: Hiện tại chọn hành trình SGN trên vé nếu chỉ có 1 segment thoả mãn
    // ADD
    function addPassengerHandle() {
      const paxData = bookingData.value.paxLists[dataForm.value.length]
      const tripSelected = getSegmentOptions(bookingData.value).length === 1 ? getSegmentOptions(bookingData.value)[0] : null

      dataForm.value.push({
        ...cloneDeep(loungeDefaultReq),
        pax: paxData || bookingData.value.paxLists[0],
        tripSelected,
        airportSelected: defaultValueAirportSelected.value || null,
      })
    }

    function handleOneForAll(finalData) {
      dataForm.value.forEach(paxItem => {
        paxItem.tripSelected = cloneDeep(finalData.tripSelected)
        paxItem.airportSelected = cloneDeep(finalData.airportSelected)
        paxItem.serviceSelected = cloneDeep(finalData.serviceSelected)
        paxItem.loungeFare = cloneDeep(finalData.loungeFare)
      })
    }

    function hasAllData(dataItem) {
      return (dataForm.value.length === 1) || isEmpty(dataItem.tripSelected) || isEmpty(dataItem.airportSelected) || isEmpty(dataItem.serviceSelected) || isEmpty(dataItem.loungeFare)
    }

    // const showPopover = ref(false)
    const isDisabled = ref(false)
    const isLoading = ref(true)
    const isConfirm = ref(false)
    const specialServicesList = ref(null)

    const totalPriceServices = computed(() => {
      const arrSelectedFare = dataForm.value.filter(i => i.loungeFare)
      if (!isEmpty(arrSelectedFare)) {
        return arrSelectedFare.reduce((total, i) => total + i?.loungeFare?.price || 0, 0)
      }
      return 0
    })

    const resetPayload = () => {
      resetLoungeReqData()

      specialServicesList.value = null
      defaultValueAirportSelected.value = null // default sân bay Tân Sơn Nhất

      isConfirm.value = false
      isLoading.value = true

      const data = cloneDeep(bookingData.value)
      email.value = data.contact.paxInfo?.email[0]?.address
      phoneNumber.value = data.contact?.paxInfo?.phone[0]?.address
    }

    // const onTogglePopover = act => {
    //   if (act === 'x') {
    //     showPopover.value = false
    //     return
    //   }

    //   showPopover.value = !showPopover.value
    // }

    function handleShowModal() {
      resetPayload()
      getListSpecialServices({ type: 'LOUNGE' })
        .then(res => {
          specialServicesList.value = res.items
          const hasTSN = res.items.find(i => i.name === 'Sân bay Tân Sơn Nhất')
          const isNullFirstData = (dataForm.value[0]?.airportSelected === null) || false
          if (hasTSN && isNullFirstData) {
            defaultValueAirportSelected.value = hasTSN
            dataForm.value[0].airportSelected = hasTSN
          }
        })
        .catch(err => {
          console.error({ err })
          specialServicesList.value = []
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    function handleHideModal() {
      resetPayload(true)
    }

    // 1. List Sân bay tu api get
    function getSpecialServicesList(specialServicesList) {
      if (isEmpty(specialServicesList)) return []
      return specialServicesList
    }

    // 2. Chọn phòng chờ
    function getLoungeServicesList(airportSelect) {
      if (!airportSelect || isEmpty(airportSelect)) return []
      return airportSelect?.airportLounges || []
    }

    function getLoungeServiceFareList(loungeFare) {
      if (!loungeFare || isEmpty(loungeFare)) return []
      return loungeFare?.airportLoungeFares || [] // FIXME
    }

    // function getListFareLounge(serviceSelect) {
    //   if (isEmpty(serviceSelect) || !serviceSelect.airportLoungeFares) return []
    //   if (isEmpty(serviceSelect.airportLoungeFares)) return []
    //   const data = cloneDeep(serviceSelect.airportLoungeFares).map(fare => ({
    //     ...fare,
    //     count: 0,
    //   }))

    // listLoungeFares.value = data
    //   return data
    // }

    // function handleChangeQuantity(item, action) {
    //   if (isEmpty(listLoungeFares.value)) return

    //   const getIndexItem = listLoungeFares.value.findIndex(
    //     i => i.index === item.index && i.name === item.name,
    //   )
    //   if (getIndexItem !== -1) {
    //     if (['increase'].includes(action)) {
    //       listLoungeFares.value[getIndexItem].count += 1
    //     }

    //     if (['decrease'].includes(action)) {
    //       const getIndexItem = listLoungeFares.value.findIndex(
    //         i => i.index === item.index && i.name === item.name,
    //       )
    //       const count = listLoungeFares.value[getIndexItem].count
    //       if (count > 0) {
    //         listLoungeFares.value[getIndexItem].count -= 1
    //       }
    //     }
    //   }
    // }

    function getAmountSelectedToShow(arrLoungeFares) {
      const arrIncrease = arrLoungeFares.filter(i => i.count > 0)
      if (!isEmpty(arrIncrease)) return arrIncrease.map(i => `${i.count} ${i.name}`).join(' - ')
      return 'Chọn loại vé & số lượng'
    }

    // const subtract3Hours = (value, timezone = '+07:00') => {
    //   if (!value) return ''

    //   const resultTime = moment
    //     .utcOffset(timezone)
    //     .subtract(3, 'hours')

    //   const d = {
    //     isoTime: resultTime.format('YYYY-MM-DDTHH:mm:ssZ'),
    //   }

    //   return d.isoTime
    // }

    const subtract3Hours = (value, timezone = '+07:00') => {
      if (!value) return ''

      const resultTime = moment(value)
        .subtract(3, 'hours')
        .utcOffset(timezone)
        .format('YYYY-MM-DDTHH:mm:ssZ')
      return resultTime
    }

    function handleAddOtherSpecialServices() {
      this.$refs.refFormObserver.validate()
        .then(success => {
          if (!success) {
            toastError({ title: 'messagesList.error', content: 'Vui lòng kiểm tra lại và nhập đầy đủ thông tin!' })
          } else {
            this.$bvModal.show('modal-api-loading')
            const data = dataForm.value
            const payload = {
              lounges: data.map(booking => ({
                type: 'LOUNGE',
                passengerName: `${booking.pax.lastName} ${booking.pax.firstName}`,
                email: email.value,
                phoneNumber: phoneNumber.value,
                bookingCode: bookingData.value?.bookingCode, // booking
                source: bookingData.value?.source, // booking
                flightNumber: `${booking.tripSelected.airline} ${booking.tripSelected.flightNumber}`, // 'VN 183', // booking
                flightTrip: `${booking.tripSelected.departure?.iataCode} ${booking.tripSelected.arrival?.iataCode}`, // 'HAN DAD', // booking
                servingFrom: subtract3Hours(booking.tripSelected.departure?.at, booking.tripSelected.departure?.timeZone),
                departureDate: convertISODateTime(booking.tripSelected.departure?.at, booking.tripSelected.departure?.timeZone).ISOdatetime,
                // departureDate: convertISODateTime(booking.tripSelected.departure?.at, 'UTC').ISOdatetime,
                airportCode: booking.airportSelected.name,
                airportLoungeId: booking.serviceSelected.id,
                loungeFare: [
                  {
                    loungeFareId: booking.loungeFare.id,
                    count: 1,
                    amount: booking.loungeFare.price,
                  },
                ],
              })),
            }

            createSpecialServices(payload)
              .then(() => {
                // console.log({ res })
              })
              .finally(() => {
                this.$bvModal.hide('modal-api-loading')
                this.$bvModal.hide('modal-reservation-modify-add-other-special-services')
              })
          }
        })
    }

    function handleRemovePaxItem(itemIndex) {
      const index = dataForm.value.findIndex((_, index) => index === itemIndex)
      if (index !== -1) dataForm.value.splice(index, 1)
    }

    return {
      refFormObserver,
      getValidationState,
      convertISODateTime,
      isLoading,
      isConfirm,
      isEmpty,
      dataForm,
      handleAddOtherSpecialServices,
      removeAccentsUpperCaseFormatter,
      trimInput,
      formatCurrency,
      upperCaseFormatter,
      handleShowModal,
      handleHideModal,
      getSegmentOptions,
      convertShortTrip,
      isDisabled,
      addPassengerHandle,
      handleRemovePaxItem,
      specialServicesList,
      getSpecialServicesList,
      getLoungeServiceFareList,
      getLoungeServicesList,
      getAmountSelectedToShow,
      totalPriceServices,
      phoneNumber,
      email,

      handleOneForAll,
      hasAllData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.select_custom {
  ::v-deep .vs__dropdown-toggle {
    min-height: 37.98px !important;
    padding: 0px !important;

    .vs__search {
      width: 0px !important;
      padding: 0px !important;
    }

    .vs__selected {
      padding-left: 0.5rem !important;
      margin-top: 0 !important;
    }

    &.vs__open-indicator {
      margin: 0px !important;
    }
  }
}
</style>
